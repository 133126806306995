import { mapGetters } from "vuex";
import { configManager, eventBus } from "@zhoujianhui/vip3-core";
export default {
  name: "AppMain",
  data() {
    const config = configManager.getConfig().theme;
    return {
      routerView: true,
      keepAliveMaxNum: config.keepAliveMaxNum,
      footerCopyright: config.footerCopyright,
      copyright: config.copyright,
      footerCopyrightIcon: config.footerCopyrightIcon
    };
  },
  computed: {
    ...mapGetters({
      visitedRoutes: "vab/visitedRoutes",
      device: "vab/device"
    }),
    cachedRoutes() {
      return this.visitedRoutes.filter(route => !route.meta.noKeepAlive).map(route => route.name);
    },
    key() {
      return this.$route.path;
    }
  },
  created() {
    //重载所有路由视图
    eventBus.$on("EVENT_RELOAD_ROUTER_VIEW", () => {
      this.routerView = false;
      this.$nextTick(() => {
        this.routerView = true;
      });
    });
  }
};