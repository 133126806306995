import { util } from "@zhoujianhui/vip3-core";
import SubMenu from "./SubMenu";
import MenuItem from "./MenuItem";
export default {
  name: "SideBarItem",
  components: {
    SubMenu,
    MenuItem
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      routeChildren: []
    };
  },
  computed: {
    menuComponent() {
      if (this.handleChildren(this.item.children, this.item) && (!this.routeChildren.children || this.routeChildren.notShowChildren)) {
        return "MenuItem";
      } else {
        return "SubMenu";
      }
    }
  },
  methods: {
    handleChildren(children = [], parent) {
      if (children === null) children = [];
      const showChildren = children.filter(item => {
        if (item.meta.isMenu) {
          this.routeChildren = item;
          return true;
        } else {
          return false;
        }
      });
      if (showChildren.length === 1 && parent.meta.parentRouteId === -1) {
        return true;
      }
      if (showChildren.length === 0) {
        this.routeChildren = {
          ...parent,
          path: "",
          notShowChildren: true
        };
        return true;
      }
      return false;
    },
    handlePath(routePath) {
      if (util.isExternal(routePath)) {
        return routePath;
      }
      if (util.isExternal(this.fullPath)) {
        return this.fullPath;
      }
      return util.buildPath(this.fullPath, routePath);
    }
  }
};