import { configManager } from "@zhoujianhui/vip3-core";
export default {
  name: "ThemeBar",
  data() {
    return {
      enableThemeBar: configManager.getConfig().theme.enableThemeBar,
      themeBarVisible: false,
      themeConfig: {
        layout: this.$store.getters["vab/layout"],
        style: this.$store.getters["vab/style"],
        enableTabsBar: this.$store.getters["vab/enableHeaderFixed"],
        enableHeaderFixed: this.$store.getters["vab/enableHeaderFixed"]
      },
      layoutOptions: [{
        label: '横向',
        value: 'horizontal'
      }, {
        label: '纵向',
        value: 'vertical'
      }],
      styleOptions: [{
        label: '蓝黑',
        value: 'default'
      }, {
        label: '蓝白',
        value: 'blue-white'
      }, {
        label: '绿黑',
        value: 'green-black'
      }, {
        label: '绿白',
        value: 'green-white'
      }, {
        label: '紫黑',
        value: 'purple-black'
      }, {
        label: '紫白',
        value: 'purple-white'
      }]
    };
  },
  created() {
    this.setTheme();
  },
  methods: {
    isMobile() {
      return document.body.getBoundingClientRect().width - 1 < 992;
    },
    setTheme() {
      if (!this.isMobile()) {
        this.$store.dispatch("vab/changeLayout", this.themeConfig.layout);
      }
      this.$store.dispatch("vab/enableHeaderFixed", this.themeConfig.enableHeaderFixed);
      this.$store.dispatch("vab/enableTabsBar", this.themeConfig.enableTabsBar);
      this.$store.dispatch("vab/changeStyle", this.themeConfig.style);
      localStorage.setItem("vue-admin-better-theme", JSON.stringify(this.themeConfig));
      document.getElementsByTagName("body")[0].className = `vue-admin-better-theme-${this.themeConfig.style}`;
    },
    handleThemeBarIconClick() {
      this.themeBarVisible = true;
    },
    handleThemeBarTextClick() {
      this.themeBarVisible = true;
    },
    handleSaveThemeClick() {
      this.setTheme();
      this.themeBarVisible = false;
    },
    handleRestoreDefaultThemeClick() {
      document.getElementsByTagName("body")[0].classList.remove(`vue-admin-better-theme-${this.themeConfig.style}`);
      const config = configManager.getConfig().theme;
      this.themeConfig.layout = config.layout;
      this.themeConfig.style = config.style;
      this.themeConfig.enableHeaderFixed = config.enableHeaderFixed;
      this.themeConfig.enableTabsBar = config.enableTabsBar;
      this.setTheme();
    }
  }
};