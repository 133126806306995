import "core-js/modules/es.array.push.js";
import { util } from "@zhoujianhui/vip3-core";
export default {
  name: "MenuItem",
  props: {
    routeChildren: {
      type: Object,
      default() {
        return null;
      }
    },
    item: {
      type: Object,
      default() {
        return null;
      }
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  methods: {
    handlePath(routePath) {
      if (util.isExternal(routePath)) {
        return routePath;
      }
      if (util.isExternal(this.fullPath)) {
        return this.fullPath;
      }
      return util.buildPath(this.fullPath, routePath);
    },
    handleLink() {
      const routePath = this.routeChildren.path;
      const target = this.routeChildren.meta.target;
      if (target === "_blank") {
        if (util.isExternal(routePath)) {
          window.open(routePath);
        } else if (util.isExternal(this.fullPath)) {
          window.open(this.fullPath);
        } else if (this.$route.path !== util.buildPath(this.fullPath, routePath)) {
          let routeData = this.$router.resolve(util.buildPath(this.fullPath, routePath));
          window.open(routeData.href);
        }
      } else {
        if (util.isExternal(routePath)) {
          window.location.href = routePath;
        } else if (util.isExternal(this.fullPath)) {
          window.location.href = this.fullPath;
        } else if (this.$route.path !== util.buildPath(this.fullPath, routePath)) {
          this.$router.push(util.buildPath(this.fullPath, routePath));
        }
      }
    }
  }
};