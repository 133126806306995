import {Model, Response} from "miragejs"

const preference = {
    models: {
        tablePreference: Model
    },

    fixtures: {
        tablePreferences: [
            {
                id: 1,
                userId: 1,
                name: "student",
                columns: [
                    {
                        name: "id",
                        visible: true
                    },
                    {
                        name: "name",
                        visible: true
                    },
                    {
                        name: "age",
                        visible: false
                    },
                    {
                        name: "address",
                        visible: false
                    },
                    {
                        name: "date",
                        visible: true
                    }
                ]
            }
        ]
    },

    routes() {
        // 获取用户所有的偏好
        this.get("/preferences/:userId", function (schema, request) {
            const userId = request.params.userId

            const tablePreferences = schema.tablePreferences.where(preference => {
                return preference.userId.toString() === userId
            })

            if (tablePreferences.models.length > 0) {
                return {
                    tablePreferences: tablePreferences.models
                }
            } else {
                return {
                    tablePreferences: []
                }
            }
        })

        // 新增用户的表格偏好
        this.post("/preferences/:userId/table-preferences", function (schema, request) {
            const userId = request.params.userId
            const tablePreferenceAddDto = JSON.parse(request.requestBody)

            console.log(userId)
            console.log(tablePreferenceAddDto)
        })

        // 编辑用户的表格偏好
        this.put("/preferences/:userId/table-preferences/:tableName", function (schema, request) {
            const userId = request.params.userId
            const tableName = request.params.tableName
            const tablePreferenceEditDto = JSON.parse(request.requestBody)

            console.log(userId)
            console.log(tableName)
            console.log(tablePreferenceEditDto)
        })

        // 删除用户的表格偏好
        this.delete("/preferences/:userId/table-preferences/:tableName", function (schema, request) {
            const userId = request.params.userId
            const tableName = request.params.tableName

            // TODO 删除tablePreferences
            schema.tablePreferences.findBy({userId: userId, name: tableName})

            return new Response(204)
        })
    }
}

export default preference