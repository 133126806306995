import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1f99644e"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["title"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_remix_icon = _resolveComponent("remix-icon");
  const _component_router_link = _resolveComponent("router-link");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass('logo-container-' + _ctx.layout)
  }, [_createVNode(_component_router_link, {
    to: "/"
  }, {
    default: _withCtx(() => [$data.logo ? (_openBlock(), _createBlock(_component_remix_icon, {
      key: 0,
      class: "logo",
      icon: $data.logo
    }, null, 8, ["icon"])) : _createCommentVNode("", true), _createElementVNode("span", {
      class: _normalizeClass(["title", {
        'hidden-xs-only': _ctx.layout === 'horizontal'
      }]),
      title: $data.title
    }, _toDisplayString($data.title), 11, _hoisted_1)]),
    _: 1
  })], 2);
}