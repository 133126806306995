import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-275ad459"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "tab-view-show"
};
const _hoisted_2 = {
  class: "vab-main"
};
const _hoisted_3 = {
  class: "vab-main main-padding"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_top_bar = _resolveComponent("top-bar");
  const _component_tabs_bar = _resolveComponent("tabs-bar");
  const _component_app_main = _resolveComponent("app-main");
  const _component_side_bar = _resolveComponent("side-bar");
  const _component_nav_bar = _resolveComponent("nav-bar");
  const _component_el_backtop = _resolveComponent("el-backtop");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vue-admin-beautiful-wrapper", $options.classObj])
  }, ['horizontal' === _ctx.layout ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(["layout-container-horizontal", $options.layoutClass])
  }, [_createElementVNode("div", {
    class: _normalizeClass($options.fixedHeaderClass)
  }, [_createVNode(_component_top_bar), _ctx.enableTabsBar ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_tabs_bar)])])) : _createCommentVNode("", true)], 2), _createElementVNode("div", _hoisted_3, [_createVNode(_component_app_main)])], 2)) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["layout-container-vertical", $options.layoutClass])
  }, [_ctx.device === 'mobile' && _ctx.collapse === false ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "mask",
    onClick: _cache[0] || (_cache[0] = (...args) => _ctx.handleFoldSideBar && _ctx.handleFoldSideBar(...args))
  })) : _createCommentVNode("", true), _createVNode(_component_side_bar), _createElementVNode("div", {
    class: _normalizeClass(["vab-main", _ctx.collapse ? 'is-collapse-main' : ''])
  }, [_createElementVNode("div", {
    class: _normalizeClass($options.fixedHeaderClass)
  }, [_createVNode(_component_nav_bar), _ctx.enableTabsBar ? (_openBlock(), _createBlock(_component_tabs_bar, {
    key: 0
  })) : _createCommentVNode("", true)], 2), _createVNode(_component_app_main)], 2)], 2)), _createVNode(_component_el_backtop)], 2);
}