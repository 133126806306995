import {Model, Response} from "miragejs"

const iam = {
    models: {
        user: Model
    },

    fixtures: {
        users: [
            {
                id: 1,
                username: "root",
                password: "root",
                roleIds: [0],
                phoneNumber: "15905147346"
            },
            {
                id: 2,
                username: "admin",
                password: "admin",
                roleIds: [1],
                phoneNumber: "13222671131"
            }
        ]
    },

    routes() {
        const config ={
            accountPasswordLoginUrl: "/account-password-login",   // 账户密码认证URL
            smsCaptchaLoginUrl: "/sms-captcha-login", // 短信验证码认证URL
            tokenLoginUrl: "/token-login", // 使用token登录的URL，主要用于前端刷新时初始化认证状态
            accessTokenHeader: "x-access-token",
            refreshTokenHeader: "x-refresh-token"
        }

        this.post(config.accountPasswordLoginUrl, (schema, request) => {
            const accountPasswordLoginInfo = JSON.parse(request.requestBody);
            const user = schema.users.findBy({username: accountPasswordLoginInfo.account})

            if (user.password === accountPasswordLoginInfo.password) {
                const token = accountPasswordLoginInfo.username === "root" ?
                    "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MCwidXNlcm5hbWUiOiJyb290IiwiZXhwIjo0MzIwMDE2NjM2Mzc5NjV9.pIvcNWm3PKTmCAGDpeRXb6ck7q_CtkVpyTFuFze1rrc" :
                    "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsImV4cCI6NDMyMDAxNjYzNjM3OTA0fQ.HaGZtEvcm-MJcn3Hem_rEUZUYh_pUUGAmjlq91YYgNg"
                const headers = {}
                headers[config.accessTokenHeader] = token
                headers[config.refreshTokenHeader] = token

                return new Response(200, headers, JSON.stringify(user));
            } else {
                return new Response(401);
            }
        })

        this.post(config.smsCaptchaLoginUrl, (schema, request) => {
            const smsCaptureLoginInfo = JSON.parse(request.requestBody);
            const user = schema.users.findBy({phoneNumber: smsCaptureLoginInfo.phoneNumber})

            if (smsCaptureLoginInfo.captchaText === "123456") {
                const token = smsCaptureLoginInfo.phoneNumber === "15905147346" ?
                    "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MCwidXNlcm5hbWUiOiJyb290IiwiZXhwIjo0MzIwMDE2NjM2Mzc5NjV9.pIvcNWm3PKTmCAGDpeRXb6ck7q_CtkVpyTFuFze1rrc" :
                    "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwidXNlcm5hbWUiOiJhZG1pbiIsImV4cCI6NDMyMDAxNjYzNjM3OTA0fQ.HaGZtEvcm-MJcn3Hem_rEUZUYh_pUUGAmjlq91YYgNg"
                const headers = {}
                headers[config.accessTokenHeader] = token
                headers[config.refreshTokenHeader] = token

                return new Response(200, headers, JSON.stringify(user));
            } else {
                return new Response(401);
            }
        })

        this.get(config.tokenLoginUrl, (schema, request) => {
            if (request.requestHeaders[config.accessTokenHeader]) {
                const user = schema.users.findBy({username: "root"})
                const token = "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MCwidXNlcm5hbWUiOiJyb290IiwiZXhwIjo0MzIwMDE2NjM2Mzc5NjV9.pIvcNWm3PKTmCAGDpeRXb6ck7q_CtkVpyTFuFze1rrc"
                const headers = {}
                headers[config.accessTokenHeader] = token
                headers[config.refreshTokenHeader] = token

                return new Response(200, headers, JSON.stringify(user));
            }
        })

        this.get("/route-accessibilities", () => {
            return new Response(200, {}, []);
        })

        this.get("/element-accessibility", (schema, request) => {
            const routePath = request.queryParams.routePath
            const elementId = request.queryParams.elementId

            if (routePath !== "/sample/page-element-auth") {
                return {
                    enable: true,
                    visible: true
                }
            }

            if (elementId === "btnEl1") {
                return {
                    enable: true,
                    visible: true
                }
            }

            if (elementId === "btnEl2") {
                return {
                    enable: false,
                    visible: false // 不可访问时，直接移除元素
                }
            }

            if (elementId === "btnEl3") {
                return {
                    enable: false,
                    visible: true
                }
            }

            if (elementId === "btnHm") {
                return {
                    enable: false,
                    visible: true
                }
            }

            if (elementId === "cbEl") {
                return {
                    enable: false,
                    visible: true
                }
            }

            if (elementId === "switchEl") {
                return {
                    enable: false,
                    visible: true
                }
            }

            if (elementId === "selectEl") {
                return {
                    enable: true,
                    visible: true
                }
            }

            return {
                enable: true,
                visible: true
            }
        })
    }
}

export default iam
