import "core-js/modules/es.array.push.js";
import { authManager, configManager, eventBus, noticeManager } from "@zhoujianhui/vip3-core";
export default {
  name: "AvatarComponent",
  computed: {
    account() {
      return this.maskPhoneNumber(authManager.getCurrentUser().phoneNumber) || authManager.getCurrentUser().username;
    },
    avatar: () => configManager.getConfig().theme.avatar + "/" + (authManager.getCurrentUser().avatar || "unknown.png")
  },
  methods: {
    handleCommand(command) {
      this[command]();
    },
    logout() {
      noticeManager.showConfirmDialog("您确定要退出" + configManager.getConfig().title + "吗?", "", () => {
        // 清除本地存储的个性化主题设置
        localStorage.removeItem("vue-admin-better-theme");
        // 发送登出事件由vip-core处理登出
        eventBus.$emit("EVENT_LOGOUT");
      });
    },
    navigateToPersonalCenter() {
      this.$router.push("/personal-center");
    },
    maskPhoneNumber(phoneNumber) {
      return phoneNumber ? phoneNumber.substring(0, 3) + "****" + phoneNumber.substring(phoneNumber.length - 4) : phoneNumber;
    }
  }
};