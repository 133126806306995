import { util } from "@zhoujianhui/vip3-core";
export default {
  name: "SubMenu",
  props: {
    routeChildren: {
      type: Object,
      default() {
        return null;
      }
    },
    item: {
      type: Object,
      default() {
        return null;
      }
    },
    fullPath: {
      type: String,
      default: ""
    }
  },
  methods: {
    handlePath(routePath) {
      if (util.isExternal(routePath)) {
        return routePath;
      }
      if (util.isExternal(this.fullPath)) {
        return this.fullPath;
      }
      return util.buildPath(this.fullPath, routePath);
    }
  }
};