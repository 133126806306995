import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-dcddf880"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-bar-container"
};
const _hoisted_2 = {
  class: "vab-main"
};
const _hoisted_3 = {
  class: "right-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_logo = _resolveComponent("logo");
  const _component_el_col = _resolveComponent("el-col");
  const _component_side_bar_item = _resolveComponent("side-bar-item");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_error_logger = _resolveComponent("error-logger");
  const _component_full_screen_bar = _resolveComponent("full-screen-bar");
  const _component_theme_bar = _resolveComponent("theme-bar");
  const _component_refresh_route = _resolveComponent("refresh-route");
  const _component_avatar = _resolveComponent("avatar");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xl: 7,
      lg: 7,
      md: 7,
      sm: 7,
      xs: 7
    }, {
      default: _withCtx(() => [_createVNode(_component_logo)]),
      _: 1
    }), _createVNode(_component_el_col, {
      xl: 12,
      lg: 12,
      md: 12,
      sm: 12,
      xs: 12
    }, {
      default: _withCtx(() => [_createVNode(_component_el_menu, {
        "background-color": $options.variables['menu-background'],
        "text-color": $options.variables['menu-color'],
        "active-text-color": $options.variables['menu-color-active'],
        "default-active": $options.activeMenu,
        mode: "horizontal",
        "menu-trigger": "hover"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.routes, route => {
          return _openBlock(), _createElementBlock(_Fragment, null, [!route.hidden ? (_openBlock(), _createBlock(_component_side_bar_item, {
            key: route.path,
            "full-path": route.path,
            item: route
          }, null, 8, ["full-path", "item"])) : _createCommentVNode("", true)], 64);
        }), 256))]),
        _: 1
      }, 8, ["background-color", "text-color", "active-text-color", "default-active"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      xl: 5,
      lg: 5,
      md: 5,
      sm: 5,
      xs: 5
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [_createVNode(_component_error_logger, {
        class: "right-panel-icon"
      }), _createVNode(_component_full_screen_bar, {
        class: "right-panel-icon"
      }), _createVNode(_component_theme_bar, {
        class: "right-panel-icon hidden-md-and-down"
      }), _createVNode(_component_refresh_route, {
        class: "right-panel-icon"
      }), _createVNode(_component_avatar)])]),
      _: 1
    })]),
    _: 1
  })])]);
}