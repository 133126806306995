import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ba871e6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "nav-bar-container"
};
const _hoisted_2 = {
  class: "left-panel"
};
const _hoisted_3 = ["title"];
const _hoisted_4 = {
  class: "right-panel"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_bread_crumb = _resolveComponent("bread-crumb");
  const _component_el_col = _resolveComponent("el-col");
  const _component_error_logger = _resolveComponent("error-logger");
  const _component_full_screen_bar = _resolveComponent("full-screen-bar");
  const _component_theme_bar = _resolveComponent("theme-bar");
  const _component_refresh_route = _resolveComponent("refresh-route");
  const _component_avatar = _resolveComponent("avatar");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 15
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      xs: 4,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("em", {
        class: _normalizeClass(["fold-unfold fas", _ctx.collapse ? 'fa-indent' : 'fa-outdent']),
        title: _ctx.collapse ? '展开' : '收起',
        onClick: _cache[0] || (_cache[0] = (...args) => $options.handleCollapse && $options.handleCollapse(...args))
      }, null, 10, _hoisted_3), _createVNode(_component_bread_crumb, {
        class: "hidden-xs-only"
      })])]),
      _: 1
    }), _createVNode(_component_el_col, {
      xs: 20,
      sm: 12,
      md: 12,
      lg: 12,
      xl: 12
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_error_logger, {
        class: "right-panel-icon"
      }), _createVNode(_component_full_screen_bar, {
        class: "right-panel-icon"
      }), _createVNode(_component_theme_bar, {
        class: "right-panel-icon hidden-xs-only"
      }), _createVNode(_component_refresh_route, {
        class: "right-panel-icon"
      }), _createVNode(_component_avatar)])]),
      _: 1
    })]),
    _: 1
  })]);
}