import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "menu-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_remix_icon = _resolveComponent("remix-icon");
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  return _openBlock(), _createBlock(_component_el_sub_menu, {
    ref: "subMenu",
    index: $options.handlePath($props.item.path),
    teleported: true
  }, {
    title: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.item.meta && $props.item.meta.icon ? (_openBlock(), _createBlock(_component_remix_icon, {
      key: 0,
      icon: $props.item.meta.icon
    }, null, 8, ["icon"])) : _createCommentVNode("", true)]), _createElementVNode("span", null, _toDisplayString($props.item.meta.title), 1)]),
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["index"]);
}