import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2517a161"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "breadcrumb-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_remix_icon = _resolveComponent("remix-icon");
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
  return _openBlock(), _createBlock(_component_el_breadcrumb, {
    class: "breadcrumb-container",
    separator: ">"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, item => {
      return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
        key: item.path
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_1, [item.meta.icon ? (_openBlock(), _createBlock(_component_remix_icon, {
          key: 0,
          icon: item.meta.icon
        }, null, 8, ["icon"])) : _createCommentVNode("", true)]), _createElementVNode("span", null, _toDisplayString(item.meta.title), 1)]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  });
}