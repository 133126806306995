import { mapGetters } from "vuex";
import Avatar from "./Avatar";
import BreadCrumb from "./BreadCrumb";
import ErrorLogger from "./ErrorLogger";
import FullScreenBar from "./FullScreenBar";
import ThemeBar from "./ThemeBar";
import refreshRoute from "./RefreshRoute";
export default {
  name: "NavBar",
  components: {
    Avatar,
    BreadCrumb,
    ErrorLogger,
    FullScreenBar,
    ThemeBar,
    refreshRoute
  },
  computed: {
    ...mapGetters({
      collapse: "vab/collapse"
    })
  },
  methods: {
    handleCollapse() {
      this.$store.dispatch("vab/changeCollapse");
    }
  }
};