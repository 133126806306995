import { mapGetters } from "vuex";
import { configManager } from "@zhoujianhui/vip3-core";
export default {
  name: "LogoComponent",
  data() {
    const config = configManager.getConfig();
    return {
      title: config.title,
      logo: config.theme.logo
    };
  },
  computed: {
    ...mapGetters({
      layout: "vab/layout"
    })
  }
};