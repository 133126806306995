import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_side_bar_item = _resolveComponent("side-bar-item", true);
  return $props.item.meta.isMenu ? (_openBlock(), _createBlock(_resolveDynamicComponent($options.menuComponent), {
    key: 0,
    item: $props.item,
    "full-path": $props.fullPath,
    "route-children": $data.routeChildren
  }, {
    default: _withCtx(() => [$props.item.children && $props.item.children.length ? (_openBlock(true), _createElementBlock(_Fragment, {
      key: 0
    }, _renderList($props.item.children, route => {
      return _openBlock(), _createBlock(_component_side_bar_item, {
        key: route.path,
        "full-path": $options.handlePath(route.path),
        item: route
      }, null, 8, ["full-path", "item"]);
    }), 128)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["item", "full-path", "route-children"])) : _createCommentVNode("", true);
}