import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["title"];
export function render(_ctx, _cache) {
  const _component_remix_icon = _resolveComponent("remix-icon");
  return _openBlock(), _createElementBlock("span", {
    title: _ctx.isFullscreen ? '退出全屏' : '进入全屏'
  }, [_createVNode(_component_remix_icon, {
    icon: _ctx.isFullscreen ? 'fas fa-compress' : 'fas fa-expand',
    onClick: _ctx.handleFullScreen
  }, null, 8, ["icon", "onClick"])], 8, _hoisted_1);
}