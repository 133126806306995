import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "menu-icon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_remix_icon = _resolveComponent("remix-icon");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  return _openBlock(), _createBlock(_component_el_menu_item, {
    index: $options.handlePath($props.routeChildren.path),
    onClick: $options.handleLink
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [$props.routeChildren.meta.icon ? (_openBlock(), _createBlock(_component_remix_icon, {
      key: 0,
      icon: $props.routeChildren.meta.icon
    }, null, 8, ["icon"])) : _createCommentVNode("", true)]), _createElementVNode("span", null, _toDisplayString($props.routeChildren.meta.title), 1), $props.routeChildren.meta && $props.routeChildren.meta.menuBadge ? (_openBlock(), _createBlock(_component_el_tag, {
      key: 0,
      type: "danger",
      effect: "dark"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString($props.routeChildren.meta.menuBadge), 1)]),
      _: 1
    })) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["index", "onClick"]);
}