import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3229cca0"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "app-main-container"
};
const _hoisted_2 = {
  class: "footer-copyright"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_remix_icon = _resolveComponent("remix-icon");
  return $data.routerView ? (_openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createBlock(_component_router_view, {
    key: $options.key,
    class: "app-main-height"
  }, {
    default: _withCtx(({
      Component
    }) => [_createVNode(_Transition, {
      mode: "out-in",
      name: "fade-transform",
      appear: ""
    }, {
      default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, {
        include: $options.cachedRoutes,
        max: $data.keepAliveMaxNum
      }, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))], 1032, ["include", "max"]))]),
      _: 2
    }, 1024)]),
    _: 1
  })), _withDirectives(_createElementVNode("footer", _hoisted_2, [_createTextVNode(" Copyright "), $data.footerCopyrightIcon ? (_openBlock(), _createBlock(_component_remix_icon, {
    key: 0,
    icon: "fas fa-copyright"
  })) : _createCommentVNode("", true), _createTextVNode(" " + _toDisplayString($data.copyright), 1)], 512), [[_vShow, $data.footerCopyright]])])) : _createCommentVNode("", true);
}