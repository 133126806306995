import { mapActions, mapGetters } from "vuex";
import { AppMain, NavBar, SideBar, TabsBar, TopBar } from "../component";
import "../assets/style/vab.scss";
import "../assets/style/element-variables.scss";
export default {
  name: "DefaultLayout",
  components: {
    TopBar,
    NavBar,
    SideBar,
    AppMain,
    TabsBar
  },
  computed: {
    ...mapGetters({
      collapse: "vab/collapse",
      device: "vab/device",
      layout: "vab/layout",
      enableTabsBar: "vab/enableTabsBar",
      enableHeaderFixed: "vab/enableHeaderFixed"
    }),
    classObj() {
      return {
        mobile: this.device === "mobile"
      };
    },
    layoutClass() {
      return {
        'fixed': this.enableHeaderFixed,
        'no-tabs-bar': !this.enableTabsBar
      };
    },
    fixedHeaderClass() {
      return {
        'fixed-header': this.enableHeaderFixed
      };
    }
  },
  beforeMount() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    const isMobile = this.handleIsMobile();
    if (isMobile) {
      //横向布局时如果是手机端访问那么改成纵向版
      this.$store.dispatch("vab/changeLayout", isMobile ? "vertical" : this.layout);
      this.$store.dispatch("vab/toggleDevice", "mobile");
      this.$store.dispatch("vab/foldSideBar");
    }
  },
  methods: {
    ...mapActions({
      handleFoldSideBar: "vab/foldSideBar"
    }),
    handleIsMobile() {
      return document.body.getBoundingClientRect().width - 1 < 992;
    },
    handleResize() {
      if (!document.hidden) {
        const isMobile = this.handleIsMobile();
        //横向布局时如果是手机端访问那么改成纵向版
        this.$store.dispatch("vab/changeLayout", isMobile ? "vertical" : this.layout);
        this.$store.dispatch("vab/toggleDevice", isMobile ? "mobile" : "desktop");
      }
    }
  }
};