import {Model} from "miragejs"

const sample = {
    models: {
        student: Model
    },

    fixtures: {
        students: [
            {
                id: 1,
                name: "王小虎",
                age: 7,
                address: "上海市普陀区金沙江路 1518 弄",
                date: "2016-05-02"
            },
            {
                id: 2,
                name: "王小虎",
                age: 7,
                address: "上海市普陀区金沙江路 1517 弄",
                date: "2016-05-04"
            },
            {
                id: 3,
                name: "王小虎",
                age: 7,
                address: "上海市普陀区金沙江路 1519 弄",
                date: "2016-05-01"
            },
            {
                id: 4,
                name: "王小虎",
                age: 7,
                address: "上海市普陀区金沙江路 1516 弄",
                date: "2016-05-03"
            }
        ]
    },

    routes() {
        this.get("/sample/students", (schema) => {
            return schema.students.all()
        })
    }
}

export default sample