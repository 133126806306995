import { mapGetters } from "vuex";
import SideBarItem from "./sidebar/SideBarItem";
import Avatar from "./Avatar";
import ErrorLogger from "./ErrorLogger";
import Logo from "./Logo";
import FullScreenBar from "./FullScreenBar";
import ThemeBar from "./ThemeBar";
import refreshRoute from "./RefreshRoute";
import variables from "../assets/style/export.module.scss";
export default {
  name: "TopBar",
  components: {
    Avatar,
    ErrorLogger,
    FullScreenBar,
    ThemeBar,
    refreshRoute,
    SideBarItem,
    Logo
  },
  data() {
    return {
      routes: this.$router.options.routes
    };
  },
  computed: {
    ...mapGetters({
      visitedRoutes: "vab/visitedRoutes"
    }),
    activeMenu() {
      const {
        meta,
        path
      } = this.$route;
      return meta.activeMenu || path;
    },
    variables() {
      return variables;
    }
  }
};