import { mapGetters } from "vuex";
import { configManager } from "@zhoujianhui/vip3-core";
import Logo from "../Logo";
import SideBarItem from "./SideBarItem";
import variables from "../../assets/style/export.module.scss";
export default {
  name: "SideBar",
  components: {
    SideBarItem,
    Logo
  },
  data() {
    return {
      uniqueOpened: configManager.getConfig().theme.uniqueOpened,
      routes: this.$router.options.routes.sort((route1, route2) => {
        return route1.meta.menuIndex - route2.meta.menuIndex;
      })
    };
  },
  computed: {
    ...mapGetters({
      collapse: "vab/collapse"
    }),
    defaultOpened() {
      return configManager.getConfig().theme.defaultOpened;
    },
    activeMenu() {
      const {
        meta,
        path
      } = this.$route;
      return meta.activeMenu || path;
    },
    variables() {
      return variables;
    }
  }
};