import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_logo = _resolveComponent("logo");
  const _component_side_bar_item = _resolveComponent("side-bar-item");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createBlock(_component_el_scrollbar, {
    class: _normalizeClass(["side-bar-container", {
      'is-collapse': _ctx.collapse
    }])
  }, {
    default: _withCtx(() => [_createVNode(_component_logo), _createVNode(_component_el_menu, {
      "background-color": $options.variables['menu-background'],
      "text-color": $options.variables['menu-color'],
      "active-text-color": $options.variables['menu-color-active'],
      "default-active": $options.activeMenu,
      collapse: _ctx.collapse,
      "collapse-transition": false,
      "default-openeds": $options.defaultOpened,
      "unique-opened": $data.uniqueOpened,
      mode: "vertical"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.routes, route => {
        return _openBlock(), _createBlock(_component_side_bar_item, {
          key: route.path,
          "full-path": route.path,
          item: route
        }, null, 8, ["full-path", "item"]);
      }), 128))]),
      _: 1
    }, 8, ["background-color", "text-color", "active-text-color", "default-active", "collapse", "default-openeds", "unique-opened"])]),
    _: 1
  }, 8, ["class"]);
}