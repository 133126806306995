import {ElMessage, ElMessageBox, ElNotification} from "element-plus"
import {noticeManager, noticeManagerInterface} from "@zhoujianhui/vip3-core"
import defaultConfig from "./config"

const elementUiNoticeManager = {
    config: defaultConfig.notice,

    init(config) {
        this.config = Object.assign(this.config, config.notice)
        // 替换平台的默认实现
        Object.assign(noticeManager, this)
    },

    [noticeManagerInterface.showMessage](message, type, options) {
        ElMessage(Object.assign({
            message: message,
            type: type,
            duration: this.config.messageDuration,
            offset: 60,
            showClose: true,
            dangerouslyUseHTMLString: true
        }, options))
    },

    [noticeManagerInterface.showConfirmDialog](message, title, confirmCallback, cancelCallback, options) {
        ElMessageBox.confirm(message, title || "温馨提示",
            Object.assign({
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                closeOnClickModal: false,
                type: "warning"
            }, options))
            .then(() => {
                if (confirmCallback) {
                    confirmCallback()
                }
            }).catch(() => {
            if (cancelCallback) {
                cancelCallback()
            }
        })
    },

    [noticeManagerInterface.showNotification](message, title, options) {
        ElNotification(Object.assign({
            message: message,
            title: title,
            duration: this.config.messageDuration,
            type: "success",
            position: "top-right"
        }, options))
    }
}

export default elementUiNoticeManager
