import "core-js/modules/es.array.push.js";
import { mapGetters } from "vuex";
import { eventBus, util } from "@zhoujianhui/vip3-core";
export default {
  name: "TabsBar",
  data() {
    return {
      affixedTabs: [],
      tabActive: "",
      routes: this.$router.options.routes
    };
  },
  mounted() {
    eventBus.$on("EVENT_LOGOUT", () => {
      this.$store.dispatch("vab/delAllVisitedRoutes");
    });
  },
  computed: {
    ...mapGetters({
      visitedRoutes: "vab/visitedRoutes"
    })
  },
  watch: {
    $route: {
      handler() {
        this.initTabs();
        this.addTabs();
        this.initActiveTab();
      },
      immediate: true
    }
  },
  methods: {
    async handleTabRemove(tabActive) {
      const view = this.visitedRoutes.find(route => tabActive === route.path);
      const {
        visitedRoutes
      } = await this.$store.dispatch("vab/delVisitedRoute", view);
      if (this.isActive(view)) {
        this.toLastTab(visitedRoutes, view);
      }
    },
    handleTabClick(tab) {
      const route = this.visitedRoutes.find(route => {
        if (tab.props.name === route.path) return route;
      });
      if (this.$route.path !== route.path) {
        this.$router.push({
          path: route.path,
          query: route.query,
          fullPath: route.fullPath
        });
      }
    },
    isActive(route) {
      return route.path === this.$route.path;
    },
    isAffixed(tab) {
      return tab.meta && tab.meta.isAffixed;
    },
    filterAffixedTabs(routes, basePath = "/") {
      let tabs = [];
      routes.forEach(route => {
        if (route.meta && route.meta.isAffixed) {
          const tabPath = util.buildPath(basePath, route.path);
          tabs.push({
            fullPath: tabPath,
            path: tabPath,
            name: route.name,
            meta: {
              ...route.meta
            }
          });
        }
        if (route.children) {
          const tempTabs = this.filterAffixedTabs(route.children, route.path);
          if (tempTabs.length >= 1) {
            tabs = [...tabs, ...tempTabs];
          }
        }
      });
      return tabs;
    },
    initTabs() {
      this.affixedTabs = this.filterAffixedTabs(this.routes);
      for (const tab of this.affixedTabs) {
        if (tab.name) {
          this.$store.dispatch("vab/addVisitedRoute", tab);
        }
      }
    },
    addTabs() {
      let route = this.$route;
      if (route.meta && route.meta.isAffixed === undefined) {
        route.meta.isAffixed = false;
      }
      this.$store.dispatch("vab/addVisitedRoute", route);
    },
    initActiveTab() {
      const activeRoute = this.visitedRoutes.find(route => route.path === this.$route.path);
      this.tabActive = activeRoute.path;
    },
    handleCommand(command) {
      this[command]();
    },
    refreshRoute() {
      eventBus.$emit("EVENT_RELOAD_ROUTER_VIEW");
    },
    async closeOthersTabs() {
      const view = await this.toThisTab();
      await this.$store.dispatch("vab/delOthersVisitedRoute", view);
    },
    async closeLeftTabs() {
      const view = await this.toThisTab();
      await this.$store.dispatch("vab/delLeftVisitedRoute", view);
    },
    async closeRightTabs() {
      const view = await this.toThisTab();
      await this.$store.dispatch("vab/delRightVisitedRoute", view);
    },
    async closeAllTabs() {
      const {
        visitedRoutes
      } = await this.$store.dispatch("vab/delAllVisitedRoutes");
      this.toLastTab(visitedRoutes);
    },
    toLastTab(visitedRoutes) {
      const latestView = visitedRoutes.slice(-1)[0];
      const lastViewPath = latestView.path || "/";
      this.$router.push(lastViewPath);
    },
    // 定位到当前Tab视图
    toThisTab() {
      return this.visitedRoutes.find(visitedRoute => visitedRoute.name === this.$route.name);
    }
  }
};