import { mapGetters } from "vuex";
// import VueClipboard from "vue-clipboard2"
import { noticeManager } from "@zhoujianhui/vip3-core";

// Vue.use(VueClipboard)

export default {
  name: "ErrorLogger",
  data() {
    return {
      // 用于控制错误日志对话框表格的可见性
      dialogTableVisible: false
    };
  },
  computed: {
    ...(mapGetters + {
      errorLogs: "error/errorLogs"
    })
  },
  methods: {
    clearAll() {
      this.dialogTableVisible = false;
      this.$store.dispatch("error/clearErrorLog");
    },
    onCopySuccess() {
      noticeManager.showMessage("复制到剪贴板成功", "success");
    },
    onCopyError() {
      noticeManager.showMessage("复制到剪贴板失败！", "error");
    }
  }
};