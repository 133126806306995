export default {
  name: "BreadCrumb",
  data() {
    return {
      list: this.getBreadcrumb()
    };
  },
  watch: {
    $route() {
      this.list = this.getBreadcrumb();
    }
  },
  methods: {
    getBreadcrumb() {
      return this.$route.matched.filter(item => item.name && item.meta.title);
    }
  }
};