import "@fortawesome/fontawesome-free/css/all.css"
import 'element-plus/dist/index.css'
import noticeManager from "./vip3-notice-element"
import createVipApp from "@zhoujianhui/vip3-core"
import createIcon from "@zhoujianhui/vip3-icon"
import deviceAdapter from "@zhoujianhui/vip-device-adapter"
import App from "./App"
import mockConfig from "../mock"
import createServer from "@zhoujianhui/ultra-miragejs";

(async () => {
    // 创建mock服务器
    if (process.env.NODE_ENV === "development") {
        createServer(mockConfig);
    }

    const app = await createVipApp(App, [
            noticeManager,
            deviceAdapter, // 设备适配器
        ]
    )

    if (app) {
        // 注册平台图标组件并配置SVG文件上下文
        const icon = createIcon({
            svgContexts: [
                require.context("@zhoujianhui", true, /\.svg$/),
                require.context("@", true, /\.svg$/)
            ]
        })
        app.use(icon)

        app.mount("#app")
    }
})()
