import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_remix_icon = _resolveComponent("remix-icon");
  const _component_el_badge = _resolveComponent("el-badge");
  return _ctx.errorLogs.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_badge, {
    value: _ctx.errorLogs.length,
    onClick: _cache[0] || (_cache[0] = $event => $data.dialogTableVisible = true)
  }, {
    default: _withCtx(() => [_createVNode(_component_remix_icon, {
      icon: "fas fa-bug"
    })]),
    _: 1
  }, 8, ["value"])])) : _createCommentVNode("", true);
}